
/* import modules */
import React, { Suspense, useReducer, useRef } from "react"

import * as THREE from "three"
import { Canvas, extend, useThree, useLoader } from "react-three-fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import seedrandom from 'seedrandom';

/* import textures */
import plankImage from '../textures/plank.jpg'


let HEADBOARD_DEPTH = 4



/* Color helper functions */

const RGB2HEX = (r, g, b) => {
  return "#"+Number(r).toString(16).padStart(2, '0') + Number(g).toString(16).padStart(2, '0') + Number(b).toString(16).padStart(2, '0')
}

/* GL Code */
extend({ OrbitControls })

const Controls = () => {
  const orbitRef = useRef()
  const { camera, gl } = useThree()

  return (
    <orbitControls
      autoRotate
      maxPolarAngle={Math.PI / 2}
      minPolarAngle={Math.PI / 5}
      args={[camera, gl.domElement]}
      ref={orbitRef}
    />
  )
}


const Room = (props) => {
  return (
    <mesh position={[0, props.parameters.height/2, props.parameters.length/2]} receiveShadow>
      <boxBufferGeometry 
        attach="geometry"
        args = {[props.parameters.width,props.parameters.height,props.parameters.length]}
      />
      <meshPhysicalMaterial 
        attach="material"
        color="#ADD8E6"
        side={THREE.BackSide}
      />
    </mesh>
  )
}


const Bed = (props) => {
  return (
    <mesh position={[0, props.parameters.height/2, props.parameters.length/2+HEADBOARD_DEPTH]} castShadow>
      <boxBufferGeometry 
        attach="geometry"
        args = {[props.parameters.width,props.parameters.height,props.parameters.length]}
      />
      <meshPhysicalMaterial 
        attach="material"
        color="beige"
      />
    </mesh>
  )
}


const Plank = ({position, dimensions}) => {
  const plankTexture = useLoader(THREE.TextureLoader, plankImage)

  let rng = seedrandom(position[0]+position[1]+position[2]+dimensions[0]+dimensions[1]+dimensions[2])

  let colorOffset = Math.floor(rng.quick() * 75);

  return (
    <mesh 
      position={[position[0]+dimensions[0]/2,position[1]+dimensions[1]/2,position[2]+dimensions[2]/2]}
      castShadow
      receiveShadow
    >
      <boxBufferGeometry 
        attach="geometry"
        args = {dimensions}
      />
      <meshPhysicalMaterial 
        attach="material"
        color={RGB2HEX(255-colorOffset, 255-colorOffset, 255-colorOffset)}
        map = {plankTexture}
      />
    </mesh>
  );
}

const PlankRow = ({position, dimensions, firstWidth, plankWidth, hRandom}) => {

  //PDL: Setup random generator
  let rng = seedrandom(position[0]+position[1]+position[2]+dimensions[0]+dimensions[1]+dimensions[2])
  
  //PDL: Construct the list of planks
  const items = []
  let targetWidth = parseFloat(dimensions[0])
  let curWidth = parseFloat(firstWidth) + Math.floor(rng.quick()*hRandom)

  items.push(<Plank
    position={[position[0], position[1] ,position[2]]}
    dimensions={[Math.min(curWidth, dimensions[0]), dimensions[1], HEADBOARD_DEPTH]}
  />)
  
  while (curWidth < targetWidth){
    let nextWidth = Math.min(plankWidth + Math.floor(rng.quick()*hRandom), targetWidth-curWidth)
    items.push(<Plank position={[position[0]+curWidth, position[1] ,position[2]]} dimensions={[nextWidth, dimensions[1] ,HEADBOARD_DEPTH]}/>)
    curWidth += nextWidth
  }

  //PDL: Render the planks
  return (
    <>
      {items}
    </>
  );
}


const PlankedHeadboard = (props) => {
  
  const items = []
  let rowHeight = parseFloat(props.parameters.plankHeight)
  let rowWidth = parseFloat(props.parameters.width)
  let hRandom = parseFloat(props.parameters.hRandom)
  let vRandom = parseFloat(props.parameters.vRandom)
  let plankWidth = parseFloat(props.parameters.plankWidth)
  let hang =  parseFloat(props.parameters.plankHang)
  let doubleHang = (props.parameters.plankDoubleHang === true)
  let hangRandom = parseFloat(props.parameters.hangRandom)

  //PDL: Setup random generator and first width
  let rng = seedrandom(rowWidth+rowHeight+plankWidth)

  let targetHeight = props.parameters.height;
  let curHeight = 0
  let curRow = 0
  while (curHeight < targetHeight) {
    let startX = 0
    let firstWidth = plankWidth
    let curRowHeight = Math.min(rowHeight + Math.floor(rng.quick()*vRandom), targetHeight-curHeight);
    let curRowWidth = rowWidth

    if (curRow % 2 == 0) {
      firstWidth = plankWidth/2
      
      startX = -hang

      let randomAddedWidth = 0
      if (hang != 0 && hangRandom != 0){
        randomAddedWidth = Math.floor(rng.quick()*hangRandom)
        startX -= Math.floor(rng.quick()*randomAddedWidth)
      }

      if (doubleHang){
        firstWidth += hang;
        curRowWidth += 2*hang+randomAddedWidth
      }
    }

    items.push(<PlankRow
      position={[startX, curHeight ,0]}
      dimensions={[curRowWidth, curRowHeight ,HEADBOARD_DEPTH]}
      firstWidth={firstWidth}
      plankWidth={plankWidth}
      hRandom={hRandom}
    />)
    
    //PDL: Loop increments
    curHeight += curRowHeight
    curRow += 1
  }

  return (
    <group
      position={[
        -parseFloat(props.parameters.width)/2,
        parseFloat(props.parameters.heightFromFloor) + parseFloat(props.parameters.height),
        0
      ]}
      scale={[1, -1, 1]}
    >
      {items}
    </group>
  );
}

const Headboard = (props) => {
  const plankTexture = useLoader(THREE.TextureLoader, plankImage)

  return (
    
    <mesh 
      position={[
        0,
        parseFloat(props.parameters.heightFromFloor) + parseFloat(props.parameters.height)/2,
        HEADBOARD_DEPTH/2
      ]}
      castShadow
    >
      <boxBufferGeometry 
        attach="geometry"
        args = {[props.parameters.width,props.parameters.height,HEADBOARD_DEPTH]}
      />
      <meshPhysicalMaterial 
        attach="material"
        color="white"
        map = {plankTexture}
      />
    </mesh>
  )
}


const Bedroom = (props) => {
  let headboard
  if (props.parameters.headboard.planked===true){
    headboard = <PlankedHeadboard parameters={props.parameters.headboard} />
  }else{
    headboard = <Headboard parameters={props.parameters.headboard} />
  }
  return (
    <>
      <Room parameters={props.parameters.room} />
      <Bed parameters={props.parameters.bed} />

      <Suspense fallback={null}>
        {headboard}
      </Suspense>
    </>
    
  );
}


const Viewport = (props) => {
  return (
    <div className="viewport">
    <Canvas
      camera={{position: [120,200,400], far:2000}}
      onCreated={({ gl }) => {
        gl.shadowMap.enabled = true
        gl.shadowMap.type = THREE.PCFSoftShadowMap
        gl.setClearColor(new THREE.Color('#373740'))
      }}
    >
      <Controls />
      <ambientLight intensity={0.5} />
      <spotLight position={[100, 200, 100]} penumbra={1} castShadow />
      <Bedroom parameters={props.parameters} />
    </Canvas>
    </div>
  )
}


/* Parameter input */
const FloatSlider = (props) => {
  return (
    <div>
      <span>{props.name}: {props.value}cm</span>
      <input
        type="range"
        min={props.min}
        max={props.max}
        step="1"
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        disabled = {props.disabled}
      />
    </div>
  );
}


const RoomParamInput = (props) => {
  const handleOnChange = (e) => {
    props.onChange({[props.name +"."+e.target.name]:e.target.value})
  }

  return (
    <>
      <h3>Room Parameteres</h3>
      <FloatSlider min={1} max={500} value={props.parameters.width} name="width" onChange={handleOnChange} />
      <FloatSlider min={1} max={500} value={props.parameters.length} name="length" onChange={handleOnChange} />
      <FloatSlider min={1} max={500} value={props.parameters.height} name="height" onChange={handleOnChange} />
    </>
  );
}


const BedParamInput = (props) => {
  const handleOnChange = (e) => {
    props.onChange({[props.name +"."+e.target.name]:e.target.value})
  }

  return (
    <>
      <h3>Bed Parameteres</h3>
      <FloatSlider min={1} max={220} value={props.parameters.width} name="width" onChange={handleOnChange} />
      <FloatSlider min={1} max={220} value={props.parameters.length} name="length" onChange={handleOnChange} />
      <FloatSlider min={1} max={150} value={props.parameters.height} name="height" onChange={handleOnChange} />
    </>
  );
}


const HeadboardParamInput = (props) => {
  const handleOnChange = (e) => {
    //TBR: May be worth moving this code to the BedroomParamInput to avoid duplicates 
    // the other param componentes
    let value = e.target.value
    if (e.target.type == 'checkbox'){
      value = e.target.checked
    }

    
    props.onChange({[props.name +"."+e.target.name]:value})
  }

  return (
    <>
      <h3>Headboard Parameteres</h3>
      <FloatSlider min={1} max={250} value={props.parameters.width} name="width" onChange={handleOnChange} />
      <FloatSlider min={1} max={200} value={props.parameters.height} name="height" onChange={handleOnChange} />
      <FloatSlider min={1} max={200} value={props.parameters.heightFromFloor} name="heightFromFloor" onChange={handleOnChange} />
      <><span>Planked: </span><input type="checkbox" checked={props.parameters.planked} name="planked" onChange={handleOnChange} /></>
      <FloatSlider
        min={10} max={200}
        value={props.parameters.plankWidth}
        name="plankWidth"
        onChange={handleOnChange}
        disabled = {(props.parameters.planked)? "" : "disabled"}
      />
      <FloatSlider
        min={3} max={20}
        value={props.parameters.plankHeight}
        name="plankHeight"
        onChange={handleOnChange}
        disabled = {(props.parameters.planked)? "" : "disabled"}
      />
      <FloatSlider
        min={0} max={50}
        value={props.parameters.hRandom}
        name="hRandom"
        onChange={handleOnChange}
        disabled = {(props.parameters.planked)? "" : "disabled"}
      />
      <FloatSlider
        min={0} max={50}
        value={props.parameters.vRandom}
        name="vRandom"
        onChange={handleOnChange}
        disabled = {(props.parameters.planked)? "" : "disabled"}
      />
      <FloatSlider
        min={0} max={50}
        value={props.parameters.plankHang}
        name="plankHang"
        onChange={handleOnChange}
        disabled = {(props.parameters.planked)? "" : "disabled"}
      />
      <FloatSlider
        min={0} max={50}
        value={props.parameters.hangRandom}
        name="hangRandom"
        onChange={handleOnChange}
        disabled = {(props.parameters.planked)? "" : "disabled"}
      />
      
    </>
  );
}


const BedroomParamInput = (props) => {
  
  return (
    <div className="parameters">
      <RoomParamInput parameters={props.parameters.room} name="room" onChange={props.onChange} />
      <BedParamInput parameters={props.parameters.bed} name="bed" onChange={props.onChange} />
      <HeadboardParamInput parameters={props.parameters.headboard} name="headboard" onChange={props.onChange} />
    </div>
  );
}


const HeadboardVis = () => {
  const [bedroomParameters, setBedroomParameter] = useReducer(
    //PDL: Reducer function
    (state, proposedChange) => {
      let newState = {...state}
      for (let key in proposedChange){
        let keyParts = key.split(".")
        //TBR: This assumes the depth of parameter nesting is 2
        newState[keyParts[0]][keyParts[1]] = proposedChange[key]
      }
      return newState
    },
    //PDL: Initial values
    {
      room: {width:300, length:300, height:240},
      bed: {width:135, length:190, height:60},
      headboard: {
        width:160, height:67, heightFromFloor:58,
        planked:true, plankWidth:77, plankHeight:10, hRandom:0, vRandom:0,
        plankHang:10, plankDoubleHang:true, hangRandom:0
      }
    }
  );

  const handleInputChange = (e) => setBedroomParameter(e)

  
  return <div className="wrap">
    <Viewport parameters={bedroomParameters} />
    <BedroomParamInput parameters={bedroomParameters} onChange={handleInputChange} />
  </div>

}

export default HeadboardVis;