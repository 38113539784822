
/* import modules */
import React from "react"
import {Switch, Route, Link} from 'react-router-dom'

/* import components */
import HeadboardVis from './components/HeadboardVis'

/* import styles */
import './App.css';




const Home = () => {
  return <main>
    <h1>Hello world</h1>
    <Link to={'/headboard'}>Headboards</Link>
  </main>
}

/* Main app */
function App() {
  return <Switch>
    <Route exact path="/" component={Home} />
    <Route path='/headboard' component={HeadboardVis} />
  </Switch>
  
}

export default App;
